export default {
  esLabel: {
    id: 'address_fields_national_id_es_label',
    defaultMessage: 'NIF/DNI',
  },
  esTooLong: {
    id: 'address_fields_national_id_es_too_long',
    defaultMessage: 'NIF/DNI is too long ({length} characters maximum)',
  },
  esFormat: {
    id: 'address_fields_national_id_es_format',
    defaultMessage: 'NIF/DNI format is not respected',
  },
  itLabel: {
    id: 'address_fields_national_id_it_label',
    defaultMessage: 'Codice fiscale',
  },
  itTooLong: {
    id: 'address_fields_national_id_it_too_long',
    defaultMessage: 'Codice fiscale is too long ({length} characters maximum)',
  },
  itFormat: {
    id: 'address_fields_national_id_it_format',
    defaultMessage: 'Codice fiscale format is not respected',
  },
  ptLabel: {
    id: 'address_fields_national_id_pt_label',
    defaultMessage: 'NIF/NIC',
  },
  ptTooLong: {
    id: 'address_fields_national_id_pt_too_long',
    defaultMessage: 'NIF/NIC is too long ({length} characters maximum)',
  },
  ptFormat: {
    id: 'address_fields_national_id_pt_format',
    defaultMessage: 'NIF/NIC format is not respected',
  },
}
