export default {
  nationalIdRequiredLabel: {
    id: 'input_address_national_id_it_label',
    defaultMessage: 'National Id',
  },
  nationalIdInformation: {
    id: 'input_address_national_id_information',
    defaultMessage: 'Your national id is needed for your receipt',
  },
  required: {
    id: 'input_address_national_id_required',
    defaultMessage: 'This field is required',
  },
}
