export default {
  label: {
    id: 'input_address_birthdate_label',
    defaultMessage: 'Birthdate (MM/DD/YYYY)',
  },
  newLabel: {
    id: 'input_address_birthdate_label_copy',
    defaultMessage: 'Birthdate',
  },
  required: {
    id: 'input_address_birthdate_required',
    defaultMessage: 'The birthdate field is required',
  },
  legalAgeError: {
    id: 'input_address_birthdate_legal_age_error',
    defaultMessage: 'You must be at least 18 years old and born after 1901.',
  },
  information: {
    id: 'input_address_birthdate_information',
    defaultMessage: 'You need to be 18 years or older.',
  },
}
